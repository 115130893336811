<script>
/* eslint-disable  */
</script>
<template>
  <div class="control-section">
    <div align='center'>
        <ejs-chart :theme='theme' align='center' id='chartcontainer' :title='title' :primaryXAxis='primaryXAxis' :primaryYAxis='primaryYAxis'
            :chartArea='chartArea' :width='width' :tooltip='tooltip'>
            <e-series-collection>
                <e-series :dataSource='quantitaVendutePuntiVendita' type='Bar' xName='x' yName='y' name='Quantità(KG)' :marker='marker'> </e-series>
                <e-series :dataSource='incassiPuntiVendita' type='Bar' xName='x' yName='y' name='Incasso(€)' :marker='marker'> </e-series>
            </e-series-collection>
        </ejs-chart>
    </div>
</div>

</template>
<style scoped>

</style>
<script>
import Vue from "vue";
import { Browser } from '@syncfusion/ej2-base';
import { ChartPlugin, BarSeries, Category, Tooltip, Legend, DataLabel} from "@syncfusion/ej2-vue-charts";

Vue.use(ChartPlugin);

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Material";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");

export default Vue.extend({
  props: {
    incassiPuntiVendita: {
      type: Array,
      required: true,
    },
    quantitaVendutePuntiVendita: {
      type: Array,
      required: true,
    },
  },
  data: function() {
    return {
      theme: theme,

      //Initializing Primary X Axis
       primaryXAxis: {
            valueType: 'Category',
            title: 'Punti Vendita',
            interval: 1,
            majorGridLines: { width: 0 }
        },

      //Initializing Primary Y Axis
          primaryYAxis:
        {
            labelFormat: '{value}',
            edgeLabelPlacement: 'Shift',
            majorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 },
            labelStyle: {
                color: 'transparent'
            }
        },
        chartArea: {
            border: {
                width: 0
            }
        },

         marker: {
                    dataLabel: {
                        visible: true,
                        position: 'Top',
                        font: {
                            fontWeight: '600', color: '#ffffff'
                        }
                    }
                },

        width : Browser.isDevice ? '100%' : '100%',
     
      tooltip: { 
        enable: true
      },
      
      title: "Incassi e Quantità Vendute"
    };
  },
  provide: {
    chart: [BarSeries, Legend, Category, Tooltip, DataLabel]
  },
  methods: {
    load: function(args) {
        let selectedTheme = location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        this.theme = selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1);
    }
  },
 
});
</script>
