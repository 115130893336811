var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',[_c('ejs-toast',{ref:"toastRef",attrs:{"id":"toast_type","position":{ X: 'Right' }}})],1),_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"observer-validate-form-stats"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"d-inline mr-2",attrs:{"label":'Data: '.concat(String(_vm.dataInizio).split(' ')[0],' - ', String(String(_vm.dataFine).split(' ')[0])),"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.configFlatPicker,"placeholder":"Seleziona Date"},model:{value:(_vm.rangeDate),callback:function ($$v) {_vm.rangeDate=$$v},expression:"rangeDate"}}),_c('small',{staticClass:"text-danger mt-25",staticStyle:{"position":"absolute"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"d-inline-block my-2 my-md-0"},[_c('b-form-checkbox',{staticClass:"my-md-3",attrs:{"value":true,"uncheck-value":false},model:{value:(_vm.tuttiProdotti),callback:function ($$v) {_vm.tuttiProdotti=$$v},expression:"tuttiProdotti"}},[_c('strong',[_vm._v("Tutti Prodotti")])])],1)],1),_c('b-col',{staticClass:"d-flex flex-column"},[_c('b-form-group',{attrs:{"label":"Seleziona Prodotti","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Prodotti","rules":_vm.tuttiProdotti !== true ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":true,"filterable":"","multiple":"","disabled":_vm.tuttiProdotti,"label":"nome","placeholder":"Prodotti","reduce":function (valore) { return ({ id: valore.id}); },"options":_vm.prodottiOption},on:{"open":_vm.getProducts,"input":_vm.limiterSelection},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Nessuna Opzione disponibile. ")]}}],null,true),model:{value:(_vm.prodotti),callback:function ($$v) {_vm.prodotti=$$v},expression:"prodotti"}}),_c('small',{staticClass:"text-danger mt-25",staticStyle:{"position":"absolute"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"align-self-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.analizeStats}},[_vm._v(" Analizza ")])],1)],1)],1)],1)],1)],1)],1),_c('statistiche-numeriche',{attrs:{"statisticheNumeriche":_vm.statisticheNumeriche}}),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('hystogram-incasso-quantita-punti-vendita',{attrs:{"incassiPuntiVendita":_vm.incassiPuntiVendita,"quantitaVendutePuntiVendita":_vm.quantitaVendutePuntiVendita}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card',[_c('hystogram-piu-venduti',{attrs:{"piuVenduti":_vm.piuVenduti}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card',[_c('PieChartIncassoMaggioreIncasso',{attrs:{"dataMaggioreIncasso":_vm.piuVendutiTorta}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card',[_c('hystogram-maggiore-incasso',{attrs:{"maggioreIncasso":_vm.maggioreIncasso}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card',[_c('PieChartQuantitaVenduta',{attrs:{"dataIncassoNelTempo":_vm.maggioreIncassoTorta}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('hystogram-rimanenze-kg',{attrs:{"rimanenzeMagazzino":_vm.rimanenzeMagazzino}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }