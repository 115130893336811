<script>
/* eslint-disable  */
</script>
<template>
  <div>
    <b-card>
      <div>
        <ejs-toast
          id="toast_type"
          ref="toastRef"
          :position="{ X: 'Right' }"
        />
      </div>
      <b-row>
        <b-col>
          <validation-observer ref="observer-validate-form-stats">
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  :label="'Data: '.concat(String(dataInizio).split(' ')[0],' - ', String(String(dataFine).split(' ')[0]))"
                  label-class="font-weight-bolder"
                  class="d-inline mr-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="data"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="rangeDate"
                      class="form-control"
                      :config="configFlatPicker"
                      placeholder="Seleziona Date"
                    />
                    <small
                      class="text-danger mt-25"
                      style="position: absolute"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <div class="d-inline-block my-2 my-md-0">
                  <b-form-checkbox
                    v-model="tuttiProdotti"
                    :value="true"
                    :uncheck-value="false"
                    class="my-md-3"
                  >
                    <strong>Tutti Prodotti</strong>
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col class="d-flex flex-column">
                <b-form-group
                  label="Seleziona Prodotti"
                  label-class="font-weight-bolder"
                  class=""
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Prodotti"
                    :rules="tuttiProdotti !== true ? 'required' : ''"
                  >
                    <v-select
                      v-model="prodotti"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="true"
                      filterable
                      multiple
                      :disabled="tuttiProdotti"
                      label="nome"
                      placeholder="Prodotti"
                      :reduce="valore => ({ id: valore.id})"
                      :options="prodottiOption"
                      @open="getProducts"
                      @input="limiterSelection"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        Nessuna Opzione disponibile.
                      </template>
                    </v-select>
                    <small
                      class="text-danger mt-25"
                      style="position: absolute"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <div class="align-self-end">
                  <b-button
                    variant="primary"
                    class=""
                    @click="analizeStats"
                  >
                    Analizza
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
    <statistiche-numeriche  :statisticheNumeriche="statisticheNumeriche" />
    <b-row no-gutters>
      <b-col
        cols="12"
      >
        <b-card>
          <hystogram-incasso-quantita-punti-vendita 
            :incassiPuntiVendita="incassiPuntiVendita"
            :quantitaVendutePuntiVendita="quantitaVendutePuntiVendita"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card>
          <hystogram-piu-venduti :piuVenduti="piuVenduti" />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card>
          <PieChartIncassoMaggioreIncasso :dataMaggioreIncasso="piuVendutiTorta" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card>
          <hystogram-maggiore-incasso :maggioreIncasso="maggioreIncasso" />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card>
          <PieChartQuantitaVenduta :dataIncassoNelTempo="maggioreIncassoTorta" />
        </b-card>      
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <hystogram-rimanenze-kg :rimanenzeMagazzino="rimanenzeMagazzino" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BRow, BCol, BCard, BFormGroup, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'
import { getProdotti } from '@/@core/api-service/catalog/products'
import { getOrganizzazioniStatistiche } from '@/@core/api-service/logistic/puntiVendita'
import StatisticheNumeriche from '../components/StatisticheNumeriche.vue'
import HystogramIncassoQuantitaPuntiVendita from '../components/HystogramIncassoQuantitaPuntiVendita.vue'
import HystogramMaggioreIncasso from '../components/HystogramMaggioreIncasso.vue'
import HystogramPiuVenduti from '../components/HystogramPiuVenduti.vue'
import HystogramRimanenzeKg from '../components/HystogramRimanenzeKg.vue'
import PieChartIncassoMaggioreIncasso from '../components/PieChartIncassoMaggioreIncasso.vue'
import PieChartQuantitaVenduta from '../components/PieChartQuantitaVenduta.vue'

Vue.use(ToastPlugin)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    flatPickr,
    vSelect,
    BFormGroup,
    BFormCheckbox,
    BButton,
    StatisticheNumeriche,
    HystogramIncassoQuantitaPuntiVendita,
    HystogramPiuVenduti,
    HystogramRimanenzeKg,
    HystogramMaggioreIncasso,
    PieChartIncassoMaggioreIncasso,
    PieChartQuantitaVenduta,
  },
  data() {
    return {
      required,
      incassiPuntiVendita: [],
      quantitaVendutePuntiVendita: [],
      dataInizio: moment().format('YYYY-MM-DD'),
      dataFine: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      locale: 'it',
      tuttiProdotti: true,
      configFlatPicker: {
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'Y-m-d',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian, // locale for this instance only
        mode: 'range',
        onChange: selectedDates => {
          // console.log(selectedDates)
          if (selectedDates.length > 1) {
            const fromYear = String(selectedDates[0].getFullYear())
            const fromMonth = String(selectedDates[0].getMonth() + 1)
            const fromDays = String(selectedDates[0].getDate())
            const toYear = String(selectedDates[1].getFullYear())
            const toMonth = String(selectedDates[1].getMonth() + 1)
            const toDays = String(selectedDates[1].getDate())
            const fullDateFrom = new Date(fromYear.concat('-', fromMonth, '-', fromDays))
            const fullDateTo = new Date(toYear.concat('-', toMonth, '-', toDays))
            /* console.log('fromYear', fromYear)
            console.log('fromMonth', fromMonth)
            console.log('fromDays', fromDays)
            console.log('toYear', toYear)
            console.log('toMonth', toMonth)
            console.log('toDays', toDays) */
            fullDateFrom.setHours(fullDateFrom.getHours() + 0)
            fullDateTo.setHours(fullDateTo.getHours() + 23)
            fullDateTo.setMinutes(fullDateTo.getMinutes() + 59)
            // console.log('fullDateTo', fullDateTo)
            let fullTo1 = new Date(fullDateTo - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T')
            let fullDateFrom1 = new Date(fullDateFrom - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T')
            fullTo1 = String(fullTo1[0].concat(' ', fullTo1[1]))
            fullDateFrom1 = String(fullDateFrom1[0].concat(' ', fullDateFrom1[1]))
            // this.rangeDate = [fullDateFrom1, fullTo1]
            /* console.log('fullTo1', fullTo1)
            console.log('fullDateFrom1', fullDateFrom1) */
            this.dataInizio = fullDateFrom1
            this.dataFine = fullTo1
            // this.getStats(Number(this.$route.params.id), fullDateFrom1, fullTo1)
          }
        },
      },
      prodotti: [],
      prodottiOption: [],
      rangeDate: 'Ultime 24h',
      palette: [
        '#ff0000',
        '#00ff00',
        '#0000ff',
        '#eeeeee',
        '#000000',
        '#4deeea',
        '#74ee15',
        '#ffe700',
        '#f000ff',
        '#001eff',
      ],
      statisticheNumeriche: {},
      piuVenduti: [],
      maggioreIncasso: [],
      piuVendutiTorta: [],
      maggioreIncassoTorta: [],
      rimanenzeMagazzino: [],
    }
  },
  mounted() {
    localize(this.locale)
    this.getStats(this.dataInizio, this.dataFine)
  },
  methods: {
    limiterSelection(e) {
      if (e.length > 10) {
        e.pop()
      }
      // this.getStats(Number(this.$route.params.id), this.dataInizio, this.dataFine)
    },
    getProducts() {
      getProdotti().then(res => {
        if (res.data.esito === 'OK') {
          this.prodottiOption = [...res.data.prodotti].filter(prodotto => prodotto.tipoProdotto === 'Unificato')
          // this.prodottiOption.unshift({ id: 0, nome: 'TUTTI I PRODOTTI'})
        }
      })
    },
    analizeStats() {
      this.$refs['observer-validate-form-stats'].validate().then(success => {
        if (success) {
          this.getStats(this.dataInizio, this.dataFine)
        }
      })
    },
    async getStats(dataInizio, dataFine) {
      // console.log('dataInizio', dataInizio)
      // console.log('dataFine', dataFine)
      // console.log('idPuntoVendita', idPuntoVendita)
      // console.log({ idPuntoVendita, dataInizio, dataFine, tuttiProdotti: this.tuttiProdotti, prodotti: this.prodotti })
      await getOrganizzazioniStatistiche({ dataInizio, dataFine, tuttiProdotti: this.tuttiProdotti, prodotti: this.prodotti }).then(res => {
        if (res.data.esito === 'OK') {
          this.statisticheNumeriche = {
            incasso: res.data.incasso,
            mediaBattute: res.data.mediaBattute,
            numeroBattute: res.data.numeroBattute,
            quantitaKg: res.data.quantitaVendutaKg,
            incassoKg: res.data.incassoKg,
            numeroScontrini: res.data.hasOwnProperty('numeroScontrini') ? res.data.numeroScontrini : undefined,
            mediaScontrini: res.data.hasOwnProperty('mediaScontrini') ? res.data.mediaScontrini : undefined,
            quantitaRimanente: res.data.quantitaRimanente,
          }
          this.incassiPuntiVendita = []
          this.quantitaVendutePuntiVendita = []
          this.incassiPuntiVendita = [...res.data.puntiVenditaMaggioreIncasso].map(el => ({ x: el.nomePuntoVendita, y: el.incassoPuntoVendita }))
          // console.log('INCASSI', this.incassiPuntiVendita)
          this.quantitaVendutePuntiVendita = [...res.data.puntiVenditaMaggioreIncasso].map(el => ({ x: el.nomePuntoVendita, y: el.quantitaVendutaPuntoVendita }))
          // console.log('QUANTITA', this.quantitaVendutePuntiVendita)
          this.maggioreIncasso = [...res.data.prodottiMaggioreIncasso].map(el => ({ x: el.nome, y: el.incasso })).slice(0, 10) 
          this.piuVenduti = [...res.data.prodottiPiuVenduti].map(el => ({ x: el.nome, y: el.quantita })).slice(0, 10)         
          const totalePiuVenduti = this.piuVenduti.reduce((a, b) => a + Number(b.y), 0)
          const totaleMaggioreIncasso = this.maggioreIncasso.reduce((a, b) => a + Number(b.y), 0)
          this.piuVendutiTorta = this.piuVenduti.map((el, index) => ({ x: el.x, y: Number((el.y * 100) / Number(totalePiuVenduti)).toFixed(2), fill: this.palette[index], text: el.x.toLowerCase() }))
          this.maggioreIncassoTorta = this.maggioreIncasso.map((el, index) => ({ x: el.x, y: Number((el.y * 100) / Number(totaleMaggioreIncasso)).toFixed(2), fill: this.palette[index], text: el.x.toLowerCase() }))
          this.rimanenzeMagazzino = [...res.data.rimanenzeMagazzino].map(el => ({ x: el.nome, y: el.quantita }))
          // console.log('this incassiPuntiVendita',  this.incassiPuntiVendita)
        } else if (res.data.esito === 'KO') {
          this.$refs.toastRef.show({
            title: 'Attenzione!', content: res.data.messaggio, cssClass: 'e-toast-info', icon: 'e-info toast-icons',
          })
        }
      }).catch(e => e)
      // console.log(this.venditeNelTempo)
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>