<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="3"
      >
        <div class="mb-2">
          <h5>
              Incasso
          </h5>
          <h3 class="mb-25 font-weight-bolder">
            {{ incasso2Decimals || 0 }} €
          </h3>
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="3"
      >
        <div
          v-if="statisticheNumeriche.numeroScontrini"
          class="mb-2"
        >
          <h5>Numero Scontrini</h5>
          <h3 class="mb-25 font-weight-bolder">
            {{ statisticheNumeriche.numeroScontrini }}
          </h3>
        </div>
        <div
          class="mb-2"
        >
          <h5>Numero Battute</h5>
          <h3 class="mb-25 font-weight-bolder">
            {{ statisticheNumeriche.numeroBattute }}
          </h3>
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="3"
      >
        <div
          v-if="statisticheNumeriche.mediaScontrini"
          class="mb-2"
        >
          <h5>Media Scontrini</h5>
          <h3 class="mb-25 font-weight-bolder">
            {{ mediScontrini2Decimals }} €
          </h3>
        </div>
        <div>
          <h5>Media Battute</h5>
          <h3 class="mb-25 font-weight-bolder">
            {{ mediaBattute2Decimals }} €
          </h3>
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="3"
      >
        <div
          class="mb-2"
        >
          <h5>Quantità Venduta</h5>
          <h3 class="mb-25 font-weight-bolder">
            {{ quantitaKg2Decimals || 0 }} Kg
          </h3>
        </div>
        <div
          class="mb-2"
        >
          <h5>Quantità Rimanente</h5>
          <h3 class="mb-25 font-weight-bolder">
            {{ statisticheNumeriche.quantitaRimanente || 0 }} Kg
          </h3>
        </div>
      </b-col>
    </b-row>
    <!-- {{ statisticheNumeriche }} -->
  </b-card>
</template>

<script>
import {
  BCol, BCard, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BCard,
  },
  props: {
    statisticheNumeriche: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mediaBattute2Decimals() {
      return Number(this.statisticheNumeriche.mediaBattute).toFixed(2)
    },
    quantitaKg2Decimals() {
      // console.log('length', String(this.statisticheNumeriche.mediaBattute).length)
      // console.log(this.statisticheNumeriche.battute !== undefined)
      try {
        if (String(this.statisticheNumeriche.quantitaKg).length >= 4) {
          // console.log('dentro if')
          const mediaBattuteToString = this.statisticheNumeriche.quantitaKg
          return mediaBattuteToString.toFixed(2)
        }
      } catch {
        return this.statisticheNumeriche.quantitaKg
      }
      return this.statisticheNumeriche.quantitaKg
    },
    incasso2Decimals() {
      // console.log('length', String(this.statisticheNumeriche.mediaBattute).length)
      // console.log(this.statisticheNumeriche.battute !== undefined)
      try {
        if (String(this.statisticheNumeriche.incasso).length > 4) {
          // console.log('dentro if')
          const mediaBattuteToString = this.statisticheNumeriche.incasso
          return mediaBattuteToString.toFixed(2)
        }
      } catch {
        return this.statisticheNumeriche.incasso
      }
      return this.statisticheNumeriche.incasso
    },
    mediScontrini2Decimals() {
      // console.log('length', String(this.statisticheNumeriche.mediaBattute).length)
      // console.log(this.statisticheNumeriche.battute !== undefined)
      return Number(this.statisticheNumeriche.mediaScontrini).toFixed(2)
    },
  },
}
</script>
<style>
</style>
